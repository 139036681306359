import { required } from '@vuelidate/validators';
import { useRuntimeConfig } from 'nuxt/app';
import addressApi from '~~/common/mixins/addressApi';
import { uuid4FormData } from '~~/common/utils';

import { INPUT_LABEL_PLACE_OUTER, INPUT_SIZE } from '~/awPlugins/app-config';
import { useUserInterfaceStore } from '~~/common/stores/userInterface';

export default {
  mixins: [
    addressApi,
  ],
  validations: {
    firstName: {
      model: {
        required,
      },
    },
    lastName: {
      model: {
        required,
      },
    },
    postCode: {
      model: {
        required,
      },
    },
    city: {
      model: {
        required,
      },
    },
    streetName: {
      model: {
        required,
      },
    },
    streetNo: {
      model: {
        required,
      },
    },
  },
  data () {
    const ucbAddressValidator = useUserInterfaceStore().getValidator('ucb_address');
    const form = {
      firstName: {
        model: '',
        type: 'text',
        name: 'firstName',
        label: this.$awt('aw.bandd.first_name'),
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        version2: true,
        labelAttrs: {
          for: 'first_name',
        },
        widgetAttrs: {
          id: 'first_name',
        },
        tokens: {
          ucbAddressValidator: ucbAddressValidator.instruction,
        },
      },
      lastName: {
        model: '',
        type: 'text',
        name: 'lastName',
        label: this.$awt('aw.bandd.last_name'),
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        version2: true,
        labelAttrs: {
          for: 'last_name',
        },
        widgetAttrs: {
          id: 'last_name',
        },
        tokens: {
          ucbAddressValidator: ucbAddressValidator.instruction,
        },
      },
      postCode: {
        model: '',
        name: 'postCode',
        type: 'typeahead',
        options: [],
        version2: true,
        label: this.$awt('aw.bandd.post_code'),
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        widgetAttrs: {
          id: 'post_code',
        },
        widgetListeners: {
          select: this.setCity,
          filter: this.filterByZipCode,
        },
        widgetProps: {
          optionTemplate: '{{label}} {{settlementName}}',
        },
        labelAttrs: {
          for: 'post_code',
        },
        tokens: {
          postCodeValidator: 'aw.bandd.post_code_format',
        },
      },
      city: {
        model: '',
        type: 'text',
        name: 'city',
        label: this.$awt('aw.bandd.city'),
        version2: true,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'city',
        },
        widgetAttrs: {
          id: 'city',
          disabled: true,
        },
      },
      countryCode: {
        model: '',
        type: 'text',
        name: 'city',
        label: this.$awt('aw.bandd.country_code'),
        labelAttrs: {
          for: 'city',
        },
        widgetAttrs: {
          id: 'city',
        },
      },
      streetName: {
        model: '',
        type: 'typeahead',
        name: 'streetName',
        options: [],
        label: this.$awt('aw.bandd.street_name'),
        version2: true,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'street_name',
        },
        widgetAttrs: {
          id: 'street_name',
        },
        widgetListeners: {
          select: this.setStreetType,
          filter: publicSpace => this.filterByPublicSpace({ publicSpace, checkoutType: this.publicSpaceFilterCheckoutType }),
        },
        widgetProps: {
          optionTemplate: '{{label}} {{type}}',
        },
      },
      streetType: {
        model: '',
        type: 'text',
        name: 'streetType',
        label: this.$awt('aw.bandd.street_type'),
        version2: true,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'street_type',
        },
        widgetAttrs: {
          id: 'street_type',
          disabled: true,
        },
      },
      streetNo: {
        model: '',
        type: 'text',
        name: 'streetNo',
        label: this.$awt('aw.bandd.street_no'),
        version2: true,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'street_no',
        },
        widgetAttrs: {
          id: 'street_no',
        },
        tokens: {
          ucbAddressValidator: ucbAddressValidator.instruction,
        },
      },
      building: {
        model: '',
        type: 'text',
        name: 'building',
        version2: true,
        label: `${this.$awt('aw.bandd.building')} (${this.$awt('aw.common.form.optional')})`,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'building',
        },
        widgetAttrs: {
          id: 'building',
        },
        tokens: {
          ucbAddressValidator: ucbAddressValidator.instruction,
        },
      },
      floor: {
        model: '',
        type: 'text',
        name: 'floor',
        version2: true,
        label: `${this.$awt('aw.bandd.floor')} (${this.$awt('aw.common.form.optional')})`,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'floor',
        },
        widgetAttrs: {
          id: 'floor',
          maxLength: 3,
        },
        tokens: {
          ucbAddressValidator: ucbAddressValidator.instruction,
        },
      },
      door: {
        model: '',
        type: 'text',
        name: 'door',
        version2: true,
        label: `${this.$awt('aw.bandd.door')} (${this.$awt('aw.common.form.optional')})`,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'door',
        },
        widgetAttrs: {
          id: 'door',
          maxLength: 5,
        },
        tokens: {
          ucbAddressValidator: ucbAddressValidator.instruction,
        },
      },
      contactPhone: {
        model: ['', '', ''],
        name: 'contactPhone',
        type: 'tel',
        version2: true,
        label: this.$awt('aw.bandd.phone_number'),
        labelAfter: this.$awt('aw.bandd.phone_number_example'),
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'contactPhone',
        },
        widgetAttrs: {
          id: 'contactPhone',
          gutter: 'sm',
          isExpand: true,
        },
        tokens: {},
      },
      label: {
        model: '',
        type: 'text',
        version2: true,
        name: 'address_label',
        label: `${this.$awt('aw.bandd.address_label')} (${this.$awt('aw.common.form.optional')})`,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'address_label',
        },
        widgetAttrs: {
          id: 'address_label',
        },
      },
      staircase: {
        model: '',
        type: 'text',
        name: 'staircase',
        version2: true,
        label: `${this.$awt('aw.bandd.staircase')} (${this.$awt('aw.common.form.optional')})`,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'staircase',
        },
        widgetAttrs: {
          id: 'staircase',
        },
      },
      district: {
        model: '',
        type: 'text',
        name: 'district',
        version2: true,
        label: `${this.$awt('aw.bandd.district')} (${this.$awt('aw.common.form.optional')})`,
        inputSize: INPUT_SIZE, // aw-form-row <label> tag size
        outerLabel: INPUT_LABEL_PLACE_OUTER,
        labelAttrs: {
          for: 'district',
        },
        widgetAttrs: {
          id: 'district',
        },
      },
      elevator: {
        model: false,
        type: 'checkbox',
        name: 'elevator',
        version2: true,
        labelAttrs: {
          for: 'elevator',
        },
        widgetAttrs: {
          id: 'elevator',
        },
        widgetProps: {
          label: this.$awt('aw.bandd.elevator'),
          labelSize: INPUT_SIZE,
        },
      },
    };
    uuid4FormData(form);
    return {
      ...form,
      id: null,
      defaultAddress: false,
      AddressInfoModel,
    };
  },
  computed: {
    /**
     * @type {'FOOD'|'NONFOOD'|undefined}
     */
    publicSpaceFilterCheckoutType: () => undefined,
  },

  mounted () {
    this.contactPhone.tokens.phoneValidator = useUserInterfaceStore().getValidator('phone_number')?.instruction;
  },
  methods: {
    setModels (addressInfoModel) {
      if (!(addressInfoModel instanceof AddressInfoModel)) {
        this.$logger.error(TypeError('addressInfoModel is not instanceof AddressInfoModel'));
      }

      this.id = addressInfoModel.id;
      this.defaultAddress = addressInfoModel.defaultAddress;
      this.firstName.model = addressInfoModel.firstName;
      this.lastName.model = addressInfoModel.lastName;
      this.label.model = addressInfoModel.label;
      const addressModel = new AddressModel(addressInfoModel.address);
      this.postCode.model = addressModel.postCode;
      this.city.model = addressModel.city;
      this.streetName.model = addressModel.streetName;
      this.streetType.model = addressModel.streetType;
      this.streetNo.model = addressModel.streetNo;
      this.building.model = addressModel.building;
      this.floor.model = addressModel.floor;
      this.door.model = addressModel.door;
      this.staircase.model = addressModel.staircase;
      this.district.model = addressModel.district;
      this.countryCode.model = addressModel.countryCode;
      this.elevator.model = addressModel.elevator;
    },
    getAddressInfoFromModels () {
      const config = useRuntimeConfig();
      return new AddressInfoModel({
        id: this.id || null,
        defaultAddress: this.defaultAddress,
        firstName: this.firstName.model,
        lastName: this.lastName.model,
        label: this.label.model,
        address: new AddressModel({
          postCode: this.postCode.model,
          city: this.city.model,
          streetName: this.streetName.model,
          streetType: this.streetType.model,
          streetNo: this.streetNo.model,
          building: this.building.model,
          floor: this.floor.model,
          door: this.door.model,
          staircase: this.staircase.model,
          district: this.district.model,
          elevator: this.elevator.model ?? false,
          countryCode: this.countryCode.model || config.public.addressOrder,
        }),
      });
    },

  },
};

const canBeNullFields = [
  'id',
  'streetType',
  'streetNo',
  'building',
  'floor',
  'door',
  'district',
  'staircase',
  'label',
  'elevator',
];

export class AddressInfoModel {
  /**
   * \@context {/api/v2/contexts/Me}
   * \@type {AddressInfoModel}
   **/
  constructor (addressInfoModel) {
    if (addressInfoModel === null) {
      this.address = new AddressModel(null);
    } else if (addressInfoModel.address !== undefined) {
      this.address = new AddressModel(addressInfoModel.address);
    } else {
      throw new TypeError('addressModel does not conform to "AddressInfoModel|null": address is missing');
    }
    for (const field of [
      'id',
      'defaultAddress',
      'firstName',
      'lastName',
      'label',
    ]) {
      this._setValue(addressInfoModel, field);
    }
  }

  _setValue (addressInfoModel, field) {
    if (addressInfoModel === null) {
      switch (field) {
        case 'id':
          this[field] = null;
          break;
        case 'defaultAddress':
          this[field] = false;
          break;
        default:
          this[field] = '';
      }
    } else if (canBeNullFields.includes(field)) {
      this[field] = addressInfoModel[field] != null ? addressInfoModel[field] : '';
    } else if (addressInfoModel[field] !== undefined) {
      this[field] = addressInfoModel[field];
    } else {
      throw new TypeError(`addressModel does not conform to 'AddressInfoModel|null': ${field} is missing`);
    }
  }
}

class AddressModel {
  /**
   * \@context {/api/v2/contexts/Me}
   * \@type {AddressModel}
   **/
  constructor (addressModel) {
    for (const field of [
      'postCode',
      'countryCode',
      'city',
      'streetName',
      'streetType', // can be null
      'streetNo',
      'building', // can be null
      'floor', // can be null
      'door', // can be null
      'district', // can be null
      'staircase', // can be null
      'elevator', // can be null
    ]) {
      if (addressModel === null) {
        this[field] = null;
      } else if (canBeNullFields.includes(field)) {
        this[field] = addressModel[field] != null ? addressModel[field] : field === 'elevator' ? false : '';
      } else if (addressModel[field] !== undefined) {
        this[field] = addressModel[field];
      } else {
        throw new TypeError(`addressModel does not conform to 'AddressModel|null' ${field} is missing`);
      }
    }
  }
}
