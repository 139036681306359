import axios from 'axios';
import otqs from '~~/common/utils/objectToQueryString';
import { useNotificationStore } from '~~/common/stores/notification';
import { ref } from 'vue';
import { useNuxtApp } from 'nuxt/app';

let locationsRequest = axios.CancelToken.source();
let addressesRequest = axios.CancelToken.source();

export default {
  data () {
    return {
      locationsPending: false,
      addressesPending: false,
      selectedPublicAreaId: null,
      lastRequestCityOptions: [],
      lastRequestZipCodeOptions: [],
    };
  },
  methods: {
    async fetchLocations (filters) {
      let isAborted = false;
      if (this.locationsPending) {
        locationsRequest.cancel('"locationsRequest" cancelled by the user.');
        locationsRequest = axios.CancelToken.source();
      }
      const MIN_CHAR_LIMIT = 2;

      let response = [];
      const isEnoughChars = filters.zipCode.length >= MIN_CHAR_LIMIT;

      if (isEnoughChars) {
        this.locationsPending = true;
        try {
          response = await this.$api.$get(
            `/public_areas${otqs({ page: 1, post_code: filters.zipCode || null })}`,
            {
              cancelToken: locationsRequest.token,
            },
          );
          if (!Array.isArray(response)) {
            response = [];
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.$logger.error(error);
          } else {
            isAborted = true;
          }
        } finally {
          this.locationsPending = false;
        }
      }

      return {
        isAborted,
        feedback: isEnoughChars ? '' : this.$awt('aw.common.form.typeahead.feedback.type_more', { minChar: MIN_CHAR_LIMIT }),
        cityOptions: response.map(addr => ({
          ...addr,
          value: addr.settlementName,
          label: addr.settlementName,
        })),
        zipCodeOptions: response.map(addr => ({
          ...addr,
          value: addr.postCode,
          label: addr.postCode,
        })),
      };
    },
    async fetchAddresses (filters) {
      let isAborted = false;
      if (this.addressesPending) {
        addressesRequest.cancel('"addressesRequest" cancelled by the user.');
        addressesRequest = axios.CancelToken.source();
      }

      if (this.selectedPublicAreaId == null) {
        const notificationStore = useNotificationStore();
        notificationStore.pushError({
          text: {
            title: this.$awt('aw.global.address_error'),
          },
        });
      }

      let response = [];

      if (filters.publicSpace.length && this.selectedPublicAreaId != null) {
        this.addressesPending = true;
        try {
          response = await this.$api.$get(
            `/public_area_addresses${otqs({ page: 1, public_area_id: this.selectedPublicAreaId, name: filters.publicSpace, checkout_type: filters.checkoutType || null })}`,
            {
              cancelToken: addressesRequest.token,
            },
          );
          if (!Array.isArray(response)) {
            response = [];
          }
        } catch (error) {
          if (!axios.isCancel(error)) {
            this.$logger.error(error);
          } else {
            isAborted = true;
          }
        } finally {
          this.addressesPending = false;
        }
      }

      return {
        isAborted,
        publicSpaces: response.map(addr => ({
          ...addr,
          value: addr.name,
          label: addr.name,
        })),
        publicSpaceTypes: response.map(addr => ({
          ...addr,
          value: addr.type,
          label: addr.type,
        })),
      };
    },
    setStreetType (option) {
      this.streetType.model = option?.type ?? '';
    },
    setZipcode (option) {
      this.postCode.options = this.lastRequestZipCodeOptions;
      this.postCode.model = option?.zipCode ?? '';
      this.selectedPublicAreaId = option?.id;
    },
    setCity (option) {
      this.city.options = this.lastRequestCityOptions;
      this.city.model = option?.settlementName ?? '';
      this.selectedPublicAreaId = option?.id;

      if (option === null) {
        if (this.streetName) {
          this.streetName.model = '';
          this.streetName.options = [];
        }
        if (this.streetType) {
          this.streetType.model = '';
        }
      }
    },
    async filterByZipCode (zipCode) {
      const response = await this.fetchLocations({ zipCode });
      const { zipCodeOptions, cityOptions } = response;
      this.postCode.options = zipCodeOptions;
      this.lastRequestCityOptions = cityOptions;
      return response;
    },
    async filterByPublicSpace ({ publicSpace, checkoutType }) {
      const response = await this.fetchAddresses({ publicSpace, checkoutType });
      const { publicSpaces, publicSpaceTypes } = response;
      this.streetName.options = publicSpaces;
      this.lastRequestPublicSpaceTypesOptions = publicSpaceTypes;
      return response;
    },
    onLocationError (param) {
      this.$logger.error(param);
    },
    findByLocation () {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAga: 0,
      };
      return navigator.geolocation.getCurrentPosition(this.getZipCode, this.onLocationError, [options]);
    },
    async getZipCode (coordinates) {
      let response = null;
      try {
        response = await this.$api.$get(`/delivery_availability?lat=${coordinates.coords.latitude}&lon=${coordinates.coords.longitude}`);

        this.setZipcode({ zipCode: response.postCode });
        await this.filterByZipCode(response.postCode);
        const postCodeOptions = this.postCode.options;
        if (postCodeOptions.length === 1) {
          const currentOption = postCodeOptions[0];
          this.setCity(currentOption);
        } else {
          this.openPostCodeTypeAhead();
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          this.$logger.error(error);
        }
      }
    },
  },
};

export function useAddressApi () {
  const zipCodeOptions = ref([]);
  const streetNameOptions = ref([]);
  const locationsPending = ref(false);
  const addressesPending = ref(false);
  const selectedPublicAreaId = ref(null);
  const lastRequestCityOptions = ref([]);
  const lastRequestZipCodeOptions = ref([]);
  const lastRequestPublicSpaceTypesOptions = ref([]);

  const { $api, $logger, $awt } = useNuxtApp();

  async function fetchLocations (filters) {
    let isAborted = false;
    if (locationsPending.value) {
      locationsRequest.cancel('"locationsRequest" cancelled by the user.');
      locationsRequest = axios.CancelToken.source();
    }
    const MIN_CHAR_LIMIT = 2;

    let response = [];
    const isEnoughChars = filters.zipCode.length >= MIN_CHAR_LIMIT;

    if (isEnoughChars) {
      locationsPending.value = true;
      try {
        response = await $api.$get(
          `/public_areas${otqs({ page: 1, post_code: filters.zipCode || null })}`,
          {
            cancelToken: locationsRequest.token,
          },
        );
        if (!Array.isArray(response)) {
          response = [];
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          $logger.error(error);
        } else {
          isAborted = true;
        }
      } finally {
        locationsPending.value = false;
      }
    }

    return {
      isAborted,
      feedback: isEnoughChars ? '' : $awt('aw.common.form.typeahead.feedback.type_more', { minChar: MIN_CHAR_LIMIT }),
      cityOptions: response.map(addr => ({
        ...addr,
        value: addr.settlementName,
        label: addr.settlementName,
      })),
      zipCodeOptions: response.map(addr => ({
        ...addr,
        value: addr.postCode,
        label: addr.postCode,
      })),
    };
  }

  async function fetchAddresses (filters) {
    let isAborted = false;
    if (addressesPending.value) {
      addressesRequest.cancel('"addressesRequest" cancelled by the user.');
      addressesRequest = axios.CancelToken.source();
    }

    if (selectedPublicAreaId.value == null) {
      const notificationStore = useNotificationStore();
      notificationStore.pushError({
        text: {
          title: $awt('aw.global.address_error'),
        },
      });
    }

    let response = [];

    if (filters.publicSpace.length && selectedPublicAreaId.value != null) {
      addressesPending.value = true;
      try {
        response = await $api.$get(
          `/public_area_addresses${otqs({ page: 1, public_area_id: selectedPublicAreaId.value, name: filters.publicSpace, checkout_type: filters.checkoutType || null })}`,
          {
            cancelToken: addressesRequest.token,
          },
        );
        if (!Array.isArray(response)) {
          response = [];
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          $logger.error(error);
        } else {
          isAborted = true;
        }
      } finally {
        addressesPending.value = false;
      }
    }

    return {
      isAborted,
      publicSpaces: response.map(addr => ({
        ...addr,
        value: addr.name,
        label: addr.name,
      })),
      publicSpaceTypes: response.map(addr => ({
        ...addr,
        value: addr.type,
        label: addr.type,
      })),
    };
  }

  async function filterByZipCode (zipCode) {
    const response = await fetchLocations({ zipCode });
    zipCodeOptions.value = response.zipCodeOptions;
    lastRequestCityOptions.value = response.cityOptions;
    return response;
  }

  async function filterByPublicSpace ({ publicSpace, checkoutType }) {
    const response = await fetchAddresses({ publicSpace, checkoutType });
    const { publicSpaces, publicSpaceTypes } = response;
    streetNameOptions.value = publicSpaces;
    lastRequestPublicSpaceTypesOptions.value = publicSpaceTypes;
    return response;
  }

  function setCity (option) {
    selectedPublicAreaId.value = option.id;
  }

  return {
    zipCodeOptions,
    streetNameOptions,
    locationsPending,
    addressesPending,
    selectedPublicAreaId,
    lastRequestCityOptions,
    lastRequestZipCodeOptions,
    lastRequestPublicSpaceTypesOptions,
    fetchLocations,
    fetchAddresses,
    filterByZipCode,
    filterByPublicSpace,
    setCity,
  };
}
